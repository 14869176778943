import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';

import PageHeader from "./components/pageHeader";
import AboutCompany from "./components/aboutCompany";
import PageFooter from "./components/pageFooter";
import MetaTags from "./components/MetaTags";

function App() {
    // console.log(window.location.pathname);
  return (
    <div className="App">
        <MetaTags/>
        <PageHeader />
        <AboutCompany />
        <PageFooter />

    </div>
  );
}

export default App;
