import React, {Component} from "react";
import {Helmet} from "react-helmet";
import screenshot from "../image/webpage_screenshot.png";

export default class MetaTags extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Kong's Aquarium Supplies Co., Ltd. - wholesaler of aquarium product </title>
                    <link rel="canonical" href="http://kongs.com.hk/" />
                    <meta name="description" content="Kong's is a wholesaler which was set up on the early of 1980,
                since then it has grown considerably expanding first to Australia in 1991 then to New Zealand in 1986 and China in 1997.
                Kong's source products from more than 10 countries and have attracted major pet suppliers such as
                the Tetra, Red Sea, Uni President, Schego and Zoo Med.
                Kong's philosophy is to grow the market and involve more people in aquarium and
                keeping to ensure the longevity of their own growth.
                "
                    />
                    <meta property="og:locale" content="zh_HK"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Kong's Aquarium Supplies Co., Ltd. - wholesaler of aquarium product"/>
                    <meta property="og:description" content="Kong's is a wholesaler which was set up on the early of 1980,
                since then it has grown considerably expanding first to Australia in 1991 then to New Zealand in 1986 and China in 1997.
                Kong's source products from more than 10 countries and have attracted major pet suppliers such as
                the Tetra, Red Sea, Uni President, Schego and Zoo Med.
                Kong's philosophy is to grow the market and involve more people in aquarium and
                keeping to ensure the longevity of their own growth.
                    "/>
                    <meta property="og:url" content="http://kongs.com.hk/"/>
                    <meta property="og:site_name" content="Kong's Aquarium Supplies Co., Ltd. - wholesaler of aquarium product"/>
                    <meta property="og:image" content={screenshot}/>
                    <meta property="og:image:width" content="1264"/>
                    <meta property="og:image:height" content="846"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:description" content="Kong's is a wholesaler which was set up on the early of 1980,
                since then it has grown considerably expanding first to Australia in 1991 then to New Zealand in 1986 and China in 1997.
                Kong's source products from more than 10 countries and have attracted major pet suppliers such as
                the Tetra, Red Sea, Uni President, Schego and Zoo Med.
                Kong's philosophy is to grow the market and involve more people in aquarium and
                keeping to ensure the longevity of their own growth."/>
                    <meta name="twitter:title" content="Kong's Aquarium Supplies Co., Ltd. - wholesaler of aquarium product"/>
                    <meta name="twitter:image" content={screenshot}/>
                </Helmet>
            </React.Fragment>
        );
    }
}