import React, {Component} from "react";
import logo from "../image/companyLogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookSquare} from "@fortawesome/free-brands-svg-icons";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons"

export default class PageFooter extends Component{
    getCurrentYear(){
        return new Date().getFullYear()
    }

    render() {
        return <footer style={{
            "color": "#9A9A9A",
        }}>
            <div className="section text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-12 mt-3 mt-md-0">
                            <a href="https://www.facebook.com/pages/category/Pet-Supplies/Kongs-Aquarium-%E6%B1%9F%E6%B0%8F%E6%B0%B4%E6%97%8F%E7%94%A8%E5%93%81-1517023775274824/"
                            target="_blank" style={{
                                "color": "#9A9A9A",
                            }}>
                                <FontAwesomeIcon icon={faFacebookSquare} size="2x"/> <span>ollow us</span>
                            </a>
                        </div>
                        {process.env.REACT_APP_IS_SHOW_SHOP_LINK === "true" && (
                            <div className="col-md-2 col-12 mt-3 mt-md-0">
                                <a href="https://kongkeeshop.com/"
                                   target="_blank" style={{
                                    "color": "#9A9A9A",
                                }}>
                                    <FontAwesomeIcon icon={faShoppingCart} size={"2x"}/> <span>Shop now</span>
                                </a>
                            </div>
                            )}

                        <div className="col mt-3 mt-md-0" style={{
                            "text-align": "right",
                        }}>
                            Copyright © {this.getCurrentYear()} Kong's Co. Ltd. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    }
}