import React, {Component} from "react";
import ChuangXingLogo from '../image/atman fish banner.png';
import AquaOneLogo from "../image/aqua_one_logo.jpg";
import TetraFishLogo from "../image/tetra-fish-logo.png";
import RedSeaLogo from "../image/red-sea-logo.png";
import uniPresidentLogo from "../image/uni-president-logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStoreAlt} from "@fortawesome/free-solid-svg-icons";

function FadeInSection(props) {
    // const [isVisible, setVisible] = React.useState(false);
    // const domRef = React.useRef();
    // React.useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => setVisible(entry.isIntersecting));
    //     });
    //     observer.observe(domRef.current);
    // }, []);

    // const [isVisible, setVisible] = React.useState(false);
    // const domRef = React.useRef();
    // React.useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         // In your case there's only one element to observe:
    //         if (entries[0].isIntersecting) {
    //
    //             // Not possible to set it back to false like this:
    //             setVisible(true);
    //
    //             // No need to keep observing:
    //             observer.unobserve(domRef.current);
    //         }
    //     });
    //
    //     observer.observe(domRef.current);
    //
    //     return () => observer.unobserve(domRef.current);
    // }, []);

    return (
        <div
            // className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            // ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default class AboutCompany extends Component {
    render() {
        return <React.Fragment>
            <div className="main">
                <div className="section text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 ml-auto mr-auto">
                                <FadeInSection>
                                    <h2 className="title">About Kong's</h2>
                                </FadeInSection>
                                    <h5 className="description fadeInLeft animated">
                                        <FadeInSection>
                                            Kong's is a wholesaler which was set up on the early of 1980, since then it has grown considerably
                                            expanding first to Australia in 1991 then to New Zealand in 1986 and China in 1997.
                                            <br/><br/>
                                        </FadeInSection>
                                        <FadeInSection>
                                            Kong's source products from more than 10 countries and have attracted major pet suppliers
                                            such as the Tetra, Red Sea, Uni President, Schego and Zoo Med.
                                            <br/><br/>
                                        </FadeInSection>
                                        <FadeInSection>
                                            Kong's philosophy is to grow the market and involve more people in aquarium and
                                            keeping to ensure the longevity of their own growth.
                                        </FadeInSection>
                                    </h5>

                                <br/>
                            </div>
                        </div>
                        <br/>
                        <br/>

                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-3">
                                    <button onClick={() => window.open("http://www.chuangxing.com.hk/", "_blank")} className="btn btn-outline-light noBorder">
                                        <div className="info">
                                            <div className="icon icon-danger">
                                                <img src={ChuangXingLogo} alt="Chuang Xing" className="imageLogo-m"/>
                                            </div>
                                            <div className="description">
                                                <h4 className="info-title">Chuang Xing</h4>
                                                <p className="description text-transform-none">
                                                    Chuang Xing commits to provide the best aquarium product, it have won worldwide recognition for its superior quality and service.
                                                </p>
                                                <a className="btn btn-link btn-danger">See more</a>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-md-3">
                                    <button onClick={() => window.open("http://www.aquaone.com.au/", "_blank")} className="btn btn-outline-light noBorder">
                                        <div className="info">
                                            <div className="icon icon-danger">
                                                <img src={AquaOneLogo} alt="Aqua One" className="imageLogo-m"/>
                                            </div>
                                            <div className="description">
                                                <h4 className="info-title">Aqua One</h4>
                                                <p className="description text-transform-none">
                                                    Aqua One has made fish keeping easier for families, enthusiasts and professionals. Their comprehensive range includes aquariums, technology, nutrition, maintenance and accessories
                                                </p>
                                                <a className="btn btn-link btn-danger">See more</a>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-md-3">
                                    <button onClick={() => window.open("http://www.tetra-fish.com/", "_blank")} className="btn btn-outline-light noBorder">
                                        <div className="info">
                                            <div className="icon icon-danger">
                                                <img src={TetraFishLogo} alt="Tetra Fish" className="imageLogo-m"/>
                                            </div>
                                            <div className="description">
                                                <h4 className="info-title">Tetra Fish</h4>
                                                <p className="description text-transform-none">
                                                    Tetra is the largest global manufacturer of ornamental fish food and the acknowledged global authority which offering thousands of products and serve more than 1,000 dealers worldwide.
                                                </p>
                                                <a className="btn btn-link btn-danger">See more</a>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-md-3">
                                    <button onClick={() => window.open("https://www.redseafish.com/", "_blank")} className="btn btn-outline-light noBorder">
                                        <div className="info">
                                            <div className="icon icon-danger">
                                                <img src={RedSeaLogo} alt="Red Sea" className="imageLogo-m"/>
                                            </div>
                                            <div className="description">
                                                <h4 className="info-title">Red Sea</h4>
                                                <p className="description text-transform-none">
                                                    Since its inception over 20 years ago, Red Sea’s vision has been to create a world in which marine and reef aquariums are the aquariums of choice for all hobbyists. This passion has guided the company throughout its product development and design.
                                                </p>
                                                <a className="btn btn-link btn-danger">See more</a>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                {/*<div className="col-md-3">*/}
                                {/*    <button onClick={() => window.open("https://www.uni-president.com.tw/", "_blank")} className="btn btn-outline-light noBorder">*/}
                                {/*        <div className="info">*/}
                                {/*            <div className="icon icon-danger">*/}
                                {/*                <img src={uniPresidentLogo} alt="Uni President" className="imageLogo-m"/>*/}
                                {/*            </div>*/}
                                {/*            <div className="description">*/}
                                {/*                <h4 className="info-title">Uni-President</h4>*/}
                                {/*                <p className="description">*/}
                                {/*                    Uni-President is an international food conglomerate based in Tainan, Taiwan. It is the largest food production company in Taiwan as well as Asia, and has a significant market share in dairy product, foods and snacks, and fish feed.*/}
                                {/*                </p>*/}
                                {/*                <a className="btn btn-link btn-danger">See more</a>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </FadeInSection>

                        {process.env.REACT_APP_IS_SHOW_SHOP_LINK === "true" && (
                            <FadeInSection>
                                <a href="https://kongkeeshop.com/" target={"_blank"}>
                                    <button type="button" className="btn btn-success mt-3 downloadCatalogBtn">
                                        <FontAwesomeIcon icon={faStoreAlt} /> <br/><span>Online Shop</span>
                                    </button>
                                </a>
                            </FadeInSection>
                            )}

                        <FadeInSection>
                            <h2 className="title">Contact Kong's</h2>
                        </FadeInSection>
                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Tel</h4>
                                            <p className="card-text">
                                                <a href="tel: +85224938237">
                                                    +852 2493 8237
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Fax</h4>
                                            <p className="card-text">
                                                <a href="tel: +85224939068">
                                                    +852 2493 9068
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Email</h4>
                                            <p className="card-text">
                                                <a href="mailto: info@kongs.com.hk">
                                                    info@kongs.com.hk
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Address</h4>
                                            <p className="card-text">
                                                <span className={"font-weight-bold"}>Kong's Aquarium Supplies Co., Ltd. </span> <br/>
                                                Flat 04, 12/F,
                                                Cheung Fung Industrial Building,
                                                23-39 Pak Tin Par Street,
                                                Tsuen Wan, N.T., Hong Kong
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </FadeInSection>
                        <FadeInSection>
                            <div className="row">

                            </div>
                        </FadeInSection>
                        <FadeInSection>
                            <div className="row">

                            </div>
                        </FadeInSection>
                        <FadeInSection>
                            <div className="row">

                            </div>
                        </FadeInSection>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}