import React, {Component} from "react";
import header1 from "../image/header1.jpg";
import header2 from "../image/header2.jpg";
import logo from "../image/companyLogo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";

export default class PageHeader extends Component {
    state = {
        headerPhoto: header2,
    };

    render(){
        return <header>
            <nav className="navbar navbar-expand-lg fixed-top navbar-transparent " color-on-scroll="300">
                <div className="container">
                    <div className="navbar-translate">
                        <a className="navbar-brand" href="#" rel="tooltip" data-placement="bottom">
                            <img src={logo} className="imageLogo-m"/>
                        </a>
                    </div>
                    <div className="collapse navbar-collapse justify-content-end" id="navigation">
                        <ul className="navbar-nav">
                            {/*<li className="nav-item">*/}
                            {/*    <a href="../index.html" className="nav-link">*/}
                            {/*        <i className="nc-icon nc-layout-11"></i> Components</a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a href="https://demos.creative-tim.com/paper-kit-2/docs/1.0/getting-started/introduction.html"*/}
                            {/*       target="_blank" className="nav-link">*/}
                            {/*        <i className="nc-icon nc-book-bookmark"/> Documentation</a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"*/}
                            {/*       href="https://twitter.com/CreativeTim" target="_blank">*/}
                            {/*        <i className="fa fa-twitter"/>*/}
                            {/*        <p className="d-lg-none">Twitter</p>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" rel="tooltip" title="Like us on Facebook" data-placement="bottom"*/}
                            {/*       href="https://www.facebook.com/CreativeTim" target="_blank">*/}
                            {/*        <i className="fa fa-facebook-square"></i>*/}
                            {/*        <p className="d-lg-none">Facebook</p>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" rel="tooltip" title="Follow us on Instagram" data-placement="bottom"*/}
                            {/*       href="https://www.instagram.com/CreativeTimOfficial" target="_blank">*/}
                            {/*        <i className="fa fa-instagram"></i>*/}
                            {/*        <p className="d-lg-none">Instagram</p>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" rel="tooltip" title="Star on GitHub" data-placement="bottom"*/}
                            {/*       href="https://www.github.com/CreativeTimOfficial" target="_blank">*/}
                            {/*        <i className="fa fa-github"></i>*/}
                            {/*        <p className="d-lg-none">GitHub</p>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="page-header" data-parallax="true"
                 style={{
                     "backgroundImage": `url(${this.state.headerPhoto})`,
                 }}>
                <div className="filter"></div>
                <div className="container">
                    <div className="motto text-center">
                        <h1>Kong's</h1>
                        <h3>Provide quality products for your pets</h3>
                        {process.env.REACT_APP_IS_SHOW_SHOP_LINK === "true" && (
                            <a href="https://kongkeeshop.com/" target={"_blank"}>
                                <button type="button" className="btn btn-success mt-3 downloadCatalogBtn">
                                    <FontAwesomeIcon icon={faShoppingCart} /> <span>&nbsp; Shop now</span>
                                </button>
                            </a>
                            )}
                        <br/>
                    </div>
                </div>
                <div className="icon-scroll"></div>
            </div>


        </header>
    }
}